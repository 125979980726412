//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import iconBetterSalary from "../../../image/iconSalaryWithoutText.png";
import iconRemote from "../../../image/iconRemoteWithoutText.png";
import iconLocation from "../../../image/iconOtherLocationWithoutText.png";
import iconLevel from "../../../image/iconLevelWithoutText.png";
import FeedbackTable from "./FeedbackTable";
import { QuestionCircleOutlined } from "@ant-design/icons";
import HelpInformationModal from "./HelpInformationModal";
import { useSelector } from "react-redux";
import { getNewDimensionsByScreenSizeFeedBack } from "../../../hooks/hooks";

//Interface
interface Props {
  dataDashboard: Array<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
  }>;
}

//Component
const FeedbackCard: React.FC<Props> = ({ dataDashboard }) => {
  //States
  const { Text } = Typography;
  const [visibleHelpInformationModal, setvisibleHelpInformationModal] =
    useState<boolean>(false);
  const { dictionary } = useSelector((state: any) => state.ui);
  const [size, setSize] = useState<number>(window.innerWidth);
  const updateWidth = () => {
    setSize(window.innerWidth);
  };

  // Effects
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, [size]);
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 24 }}
      lg={{ span: 24 }}
      xl={{ span: 24 }}
      xxl={{ span: 24 }}
      style={{
        height: "100%",
        padding: "1%",
        borderRadius: 16,
        border: "1px solid",
        borderColor: "#00000020",
        backgroundColor: 'white'
      }}
    >
      <Row
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 13,
          marginBottom: 13,
        }}
      >
        <Col
          xs={{ offset: 1 }}
          sm={{ offset: 1 }}
          md={{ offset: 1 }}
          lg={{ offset: 1 }}
          xl={{ offset: 1 }}
          xxl={{ offset: 1 }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {dictionary["dashboard-title-feedback"]}
          </Text>
        </Col>

        <Col>
          <Button
            onClick={() => setvisibleHelpInformationModal(true)}
            shape="circle"
            style={{ border: "none" }}
            icon={<QuestionCircleOutlined />}
          />
        </Col>
        <HelpInformationModal
          visible={visibleHelpInformationModal}
          handleOnCloseModal={() => setvisibleHelpInformationModal(false)}
        />
      </Row>

      <Row
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: "15%",
          marginBottom: getNewDimensionsByScreenSizeFeedBack(size),
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={{ span: 12, offset: 12 }}
          lg={{ span: 12, offset: 12 }}
          xl={{ span: 12, offset: 12 }}
          xxl={{ span: 12, offset: 12 }}
          style={{ height: "100%", display: "flex", justifyContent: "center" }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{ height: "100%" }}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 12, fontFamily: "Muli" }}>
                  {" "}
                  {dictionary["dashboard-feedback-icon-better-salary"]}
                </Text>
              </Row>
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconBetterSalary}
                  style={{ height: 50, width: 50 }}
                  alt={iconBetterSalary}
                />
              </Row>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{ height: "100%" }}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 12, fontFamily: "Muli" }}>
                  {dictionary["dashboard-feedback-icon-other-location"]}
                </Text>
              </Row>

              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconLocation}
                  style={{ height: 50, width: 50 }}
                  alt={iconLocation}
                />
              </Row>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{ height: "100%" }}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 12, fontFamily: "Muli" }}>
                  {dictionary["dashboard-feedback-icon-remote"]}
                </Text>
              </Row>

              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconRemote}
                  style={{ height: 50, width: 50 }}
                  alt={iconRemote}
                />
              </Row>
            </Col>

            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{ height: "100%" }}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 12, fontFamily: "Muli" }}>
                  {dictionary["dashboard-feedback-icon-level"]}
                </Text>
              </Row>

              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconLevel}
                  style={{ height: 50, width: 50 }}
                  alt={iconLevel}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="contenedor"
        style={{ height: "60%", width: "100%", overflowY: "auto" }}
      >
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 24 }}
        >
          {dataDashboard.length > 0 ? (
            <Col>
              <FeedbackTable dataDashboard={dataDashboard} />
            </Col>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

//Export
export default FeedbackCard;
